import { RichText } from 'components/sanaText';
import PropTypes from 'prop-types';
import { WithProductClickTracking } from 'components/objects/analytics';
import WithProductRoute from 'components/objects/productList/WithProductRoute';
import { sanitizeId } from 'utils/helpers';
import { Link } from 'components/primitives/links';

const SubstituteProductMessage = ({ textKey, id, url, messageStyle, titleStyle }) => {
  return (
    <span className={messageStyle}>
      <RichText textKey={textKey} formatWith={[
        <WithProductClickTracking>
          {handleClick => (
            <WithProductRoute product={{ id, url }}>
              {(productUrl, productRouteBuilder) => (
                <Link onClick={handleClick} url={productUrl} to={productRouteBuilder} className={titleStyle}>
                  <span id={sanitizeId('substituteProductTitle' + id)}>
                    {id}
                  </span>
                </Link>
              )}
            </WithProductRoute>
          )}
        </WithProductClickTracking>,
      ]}
      />
    </span>
  );
};

SubstituteProductMessage.propTypes = {
  textKey: PropTypes.string,
  id: PropTypes.string,
  url: PropTypes.string,
  messageStyle: PropTypes.string,
  titleStyle: PropTypes.string,
};

export default SubstituteProductMessage;
